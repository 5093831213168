// i18next-extract-mark-ns-start blog

import {BlogArticle, BlogTitleH2, Left, PostImg} from 'components/blog/BlogComponents';
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {colors} from 'global.config';
import BlogLayout from 'layouts/BlogLayout';
import {RichText} from 'prismic-reactjs';
import React, {useCallback, useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import {serializeHyperlink} from 'utils/serializeHyperlink';
import {getPostsFromPrismicEdges, mapCategory} from 'utils/blog-utils';
import {CloseIcon} from 'components/CloseIcon';
import {BlogChip} from 'components/blog/BlogChip';
import {BlogCategoryType, BlogPost} from '../../types/BlogTypes';
import {setMomentLocale} from 'utils/helpers';
import {useLocation} from '@reach/router';

const Info = styled.p`
  color: #acb6bb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 2rem;
`;

const ImageLink = styled(Link)`
  margin-bottom: 1rem;
  position: relative;
  display: block;
  background: #f3f4f6;

  img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin-bottom: 0;
  }
`;

const ReadMore = styled.div`
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ea7ec3;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
  }
`;

const Loading = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: ${colors.pink};
  margin-bottom: 2rem;
`;

const Summary = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #475157;
  margin: 0 0 2rem;

  > p {
    margin: unset;
  }
`;

const Category = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #aab4e9;
  margin: 0 0 0.5rem;
`;

const Blog: React.FC<PageProps<any, any> & {prismic: any}> = (props) => {
  const {t} = useI18next();
  setMomentLocale('en-gb');

  const {data, pageContext, prismic} = props;
  const [categories] = useState<BlogCategoryType[]>(
    data.categories.allCategorys.edges.map(mapCategory) || []
  );

  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [pageInfo, setPageInfo] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
    endCursor: ''
  });

  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const tag = urlParams.get('tags');

  useEffect(() => {
    const fetchPostsData = async () => {
      const {data} = await prismic.load({
        variables: {
          prismicLang: pageContext.prismicLang,
          ...(tag !== null ? {tags: [tag]} : {})
        }
      });
      setPageInfo(data.allPosts.pageInfo);
      const posts = getPostsFromPrismicEdges({
        posts: data.allPosts.edges,
        categories
      });
      setPosts(posts);
    };

    fetchPostsData();
  }, [tag]);

  const popularPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.popularPosts.allPosts.edges,
    categories
  });
  const mustReadPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.mustReadPosts.allPosts.edges,
    categories
  });

  const fetchMore = async () => {
    const {data} = await prismic.load({
      variables: {
        after: pageInfo.endCursor,
        prismicLang: pageContext.prismicLang,
        ...(tag !== null ? {tags: [tag]} : {})
      }
    });
    setPageInfo(data.allPosts.pageInfo);
    const newPosts = getPostsFromPrismicEdges({
      posts: data.allPosts.edges,
      categories
    });
    setPosts([...posts, ...newPosts]);
  };

  return (
    <>
      <SEO title="Blog" bodyAttributes={{class: 'blog'}} />
      <BlogLayout
        formBanner
        categories={categories}
        popularPosts={popularPosts}
        mustReadPosts={mustReadPosts}>
        <Left>
          {tag && (
            <BlogChip to="/blog/">
              #{decodeURI(tag)} <CloseIcon title={t('Remove tag')} />
            </BlogChip>
          )}
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchMore}
            hasMore={pageInfo && pageInfo.hasNextPage}
            loader={<Loading>Loading...</Loading>}>
            {posts.map((post: BlogPost) => {
              return (
                <BlogArticle key={post.uid!}>
                  {post.category && (
                    <Category to={'/blog/' + post.category.slug + '/'}>
                      {post.category.title}
                    </Category>
                  )}
                  <BlogTitleH2>
                    <Link
                      to={post.slug as string}
                      title={RichText.asText(post.title)}
                      language={post.lang}>
                      {RichText.asText(post.title)}
                    </Link>
                  </BlogTitleH2>
                  <Info>
                    {post.author_card?.author_name && (
                      <Link to={`/author/${post.author_card._meta.uid}/`}>
                        {RichText.asText(post.author_card.author_name)}
                      </Link>
                    )}
                    {post.author_card && ` | `}
                    {(post.updated_at ? t('Originally published') : t('Published')) +
                      ': ' +
                      post.firstPublicationDate}
                    {post.updated_at ? ' | ' + t('Updated') + ': ' + post.updated_at : ''}
                  </Info>
                  {post.imageUrl && (
                    <ImageLink
                      to={`/blog/${post.uid}/`}
                      title={RichText.asText(post.title)}
                      language={post.lang}
                      style={{paddingTop: `${post.imageRatio}%`}}>
                      <PostImg src={post.imageUrl} alt={RichText.asText(post.title)} />
                    </ImageLink>
                  )}
                  <Summary>
                    <RichText
                      render={post.summary}
                      // @ts-ignore
                      serializeHyperlink={serializeHyperlink}
                    />
                  </Summary>
                  <ReadMore>
                    <Link
                      to={post.slug as string}
                      title={RichText.asText(post.title)}
                      language={post.lang}>
                      <Trans>Read More</Trans>
                    </Link>
                  </ReadMore>
                </BlogArticle>
              );
            })}
          </InfiniteScroll>
        </Left>
      </BlogLayout>
    </>
  );
};

export default Blog;

export const query = graphql`
  query BlogPosts($after: String, $prismicLang: String, $tags: [String!]) {
    locales: allLocale(filter: {ns: {in: ["common", "blog"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    posts: prismic {
      allPosts(
        sortBy: meta_firstPublicationDate_DESC
        after: $after
        lang: $prismicLang
        tags: $tags
        first: 10
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            title
            body
            image
            summary
            category
            author
            updated_at
            author_card {
              ... on PRISMIC_Author {
                author_name
                _meta {
                  uid
                }
              }
            }
            _meta {
              uid
              lang
              alternateLanguages {
                lang
              }
              tags
              firstPublicationDate
            }
          }
        }
      }
    }
    categories: prismic {
      allCategorys(lang: $prismicLang) {
        edges {
          node {
            title
            _meta {
              uid
              lang
            }
            order
          }
        }
      }
    }
    popularPosts: prismic {
      allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "Popular"
        first: 3
        lang: $prismicLang
      ) {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }
    }
    mustReadPosts: prismic {
      allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "Must Read"
        first: 3
        lang: $prismicLang
      ) {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
